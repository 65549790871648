<i18n>
{
  "de": {
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (t)",
      "s123e": "Scope 1-3 Emissionen (t)",
      "per": "Primärenergie (erneuerbar) (MWh)"
    },
    "units": {
      "ee": "MWh",
      "s12e": "t",
      "s123e": "t",
      "per": "MWh"
    },
    "xLabels": {
      "first_measure_year": "Liegenschaft (sortiert nach Jahr der ersten Massnahme)",
      "reduction":  "Liegenschaft (sortiert nach Reduktionspotenzial)"
    },
    "reductionTooltip": "Reduktion",
    "descriptionLabel": "Liegenschaft",
    "reductionLabel": "Reduktion in Tonnen",
    "default": "Reduktion",
    "rest": "Rest"
  }
}
</i18n>

<template>
  <div class="c-kpi-pareto-chart">
    <ExportableBarChart v-if="buildings" :chart-data="chartData" :options="options" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'

export default {
  mixins: [
    //
    ChartOptionsMixin, // provides onChartClick
  ],

  components: {
    ExportableBarChart,
  },

  props: {
    scenario: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
    sortby: {
      type: String,
      default: 'reduction',
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (%)`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    bigUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getBigTargetUnit()
    },

    buildings() {
      let buildings = [
        ...this.reportChartData
          .filter((b) => b.scenarios_data !== null)
          .filter((b) => this.kpiType in b.kpis && b.scenarios_data.every((s) => this.kpiType in s.kpis)),
      ]
      if (this.sortby === 'first_measure_year') {
        buildings.sort((a, b) => this.getVal(a).first_measure_year - this.getVal(b).first_measure_year)
      } else if (this.sortby === 'reduction') {
        buildings.sort(
          (a, b) =>
            b.kpis[this.kpiType] -
            this.getVal(b).kpis[this.kpiType] -
            (a.kpis[this.kpiType] - this.getVal(a).kpis[this.kpiType])
        )
      }
      return buildings
    },

    totalValue() {
      return this.buildings.reduce((pv, cv) => pv + cv.kpis[this.kpiType], 0)
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onChartClick,
        scales: {
          xAxis: {
            stacked: true,
            title: {
              display: true,
              text: this.$t(`xLabels.${this.sortby}`),
            },
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            displayColors: false,
            callbacks: {
              title: (items) => {
                return `${this.chartData.datasets[1].data[items[0].dataIndex]}`
              },
              label: (item) => {
                return `${this.$t('reductionTooltip')}: ${this.chartData.datasets[2].data[item.dataIndex]} ${
                  this.bigUnit
                }`
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      const datasets = []
      const series = []
      const descriptions = []
      const reductions = []
      const labels = []
      let rest = []
      let last
      let restValue = 0

      let cumulative = this.totalValue
      this.buildings.map((building, index) => {
        // https://stackoverflow.com/questions/38703114/chart-js-plugin-for-making-waterfall-charts
        const vStart = cumulative
        const value = building.kpis[this.kpiType] - this.getVal(building).kpis[this.kpiType]
        cumulative -= value
        // Handle the first 20 buildings, and then add a "rest" building
        if (index < 20) {
          last = (cumulative / this.totalValue) * 100
          descriptions.push(building.description)
          series.push([last, (vStart / this.totalValue) * 100])
          reductions.push(this.formatNumber(value / 1000, 1))
          labels.push(
            building.description.length < 30 ? building.description : building.description.substring(0, 30) + '...'
          )
        } else {
          restValue += value
          rest = [last, (vStart / this.totalValue) * 100]
        }
      })
      // Add the "rest" building, if there are more than 20 buildings
      if (this.buildings.length > 20) {
        descriptions.push(this.$t('rest'))
        series.push(rest)
        reductions.push(this.formatNumber(restValue / 1000, 1))
        labels.push(this.$t('rest'))
      }

      datasets.push({
        label: this.$t('default'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: series,
        backgroundColor: this.scenario.color,
        borderColor: this.scenario.color,
      })
      datasets.push({
        label: this.$t('descriptionLabel'),
        hidden: true,
        data: descriptions,
      })
      datasets.push({
        label: this.$t('reductionLabel'),
        hidden: true,
        data: reductions,
      })

      return {
        labels: labels,
        datasets: datasets,
      }
    },
  },

  methods: {
    getVal(building) {
      return building.scenarios_data.find((m) => m.scenario_id === this.scenario.id)
    },

    // Override the mixin method
    onChartClick(event, items) {
      if (items.length !== 0) {
        if (items[0].index < 20) {
          this.$emit('click', this.buildings[items[0].index])
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-kpi-pareto-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

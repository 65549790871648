<i18n>
{
  "de": {
    "groupByProducer": "Nach Herkunft",
    "groupByConsumer": "Nach Verwendungszweck",
    "pvParetoChart": "Ausbau PV im gewählten Szenario",
    "pvParetoChartAll": "Ausbau PV Best-Case",
    "production": "Stromproduktion (pro m2)",
    "absoluteProduction": "Stromproduktion",
    "peakProduction": "Nennleistung",
    "loadingMessage": "Daten werden geladen. Bitte warten..."
  }
}
</i18n>

<template>
  <Tabs group="electricity-section" class="electricity-section">
    <Tab :title="$t('groupByConsumer')">
      <KpiByUsageChart
        v-if="reportLoaded"
        key="electricity-by-usage-chart"
        :kpi-type="'el_demand'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab :title="$t('groupByProducer')">
      <OwnUseChart
        v-if="reportLoaded"
        key="electricity-demand-chart"
        demand-perspective
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab :title="$t('production')">
      <OwnUseChart
        v-if="reportLoaded"
        key="electricity-production-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab :title="$t('absoluteProduction')">
      <OwnUseChart
        v-if="reportLoaded"
        key="electricity-production-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
        :absolute="true"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab :title="$t('peakProduction')">
      <PeakProductionChart
        v-if="reportLoaded"
        key="peak-production-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab v-if="portfolio.enabled_features.PV_PROFITABILITY" :title="$t('pvParetoChart')">
      <PvParetoChart
        v-if="reportLoaded"
        key="pv-pareto-chart"
        :portfolio="portfolio"
        :report-chart-data="reportChartData"
        :scenario="scenario"
        @click="onParetoClick"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
    <Tab v-if="portfolio.enabled_features.PV_PROFITABILITY" :title="$t('pvParetoChartAll')">
      <PvParetoChart
        v-if="reportLoaded"
        key="pv-pareto-chart-all"
        :portfolio="portfolio"
        :report-chart-data="reportChartData"
        :report-loaded="reportLoaded"
        @click="onParetoClick"
      />
      <div v-else class="placeholder">{{ $t('loadingMessage') }}</div>
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart.vue'
import OwnUseChart from '@/components/report/charts/OwnUseChart.vue'
import PvParetoChart from '@/components/report/charts/PvParetoChart.vue'
import PeakProductionChart from '@/components/report/charts/PeakProductionChart.vue'

export default {
  components: {
    KpiByUsageChart,
    OwnUseChart,
    PvParetoChart,
    PeakProductionChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
    reportLoaded: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },

  methods: {
    onParetoClick(building) {
      this.$router.push({
        name: 'buildingDetails',
        params: { portfolio_id: this.portfolio.id, building_id: building.building_id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

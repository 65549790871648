<i18n>
{
  "de": {
    "investmentCostsComparisson": "Szenarienvergleich",
    "investmentCostsPath": "Übersicht",
    "pvNpvComparisson": "Wirtschaftlichkeit Photovoltaik"
  }
}
</i18n>

<template>
  <Tabs group="costs-section-tabs" class="costs-section">
    <Tab key="investmentCostsPath" :title="$t('investmentCostsPath')">
      <CostChart key="cost-chart" :portfolio="portfolio" :query="query" :scenario="scenario" />
    </Tab>
    <Tab key="investmentCostsComparisson" :title="$t('investmentCostsComparisson')">
      <ScenarioCostChart key="scenario-cost-chart" :portfolio="portfolio" :report-chart-data="reportChartData" />
    </Tab>
    <Tab v-if="portfolio.enabled_features.PV_PROFITABILITY" key="pvNpvComparisson" :title="$t('pvNpvComparisson')">
      <ScenarioPvNpvChart key="scenario-pv-npv-chart" :portfolio="portfolio" :report-chart-data="reportChartData" />
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import CostChart from '@/components/report/charts/CostChart.vue'
import ScenarioCostChart from '@/components/report/charts/ScenarioCostChart.vue'
import ScenarioPvNpvChart from '@/components/report/charts/ScenarioPvNpvChart.vue'

export default {
  components: {
    CostChart,
    ScenarioCostChart,
    ScenarioPvNpvChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
    reportChartData: {
      type: Array,
      required: true
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },
}
</script>

<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="heating-type-chart">
    <template #default>
      <FaultsContainer :faults="data.faulty" :portfolio="portfolio" />
      <ExportableBarChart :chart-data="chartData" :options="options" />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    ExportableBarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      data: null,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, { title: items[0].label, unit: 'CHF', suffix: false }),
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      let datasets = []
      datasets.push({
        label: this.$t('measureHEATING'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.heating.costs,
        backgroundColor: colorPalettes.measureColors['HEATING'],
        borderColor: colorPalettes.measureColors['HEATING'],
      })
      datasets.push({
        label: this.$t('measurePV'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.pv.costs,
        backgroundColor: colorPalettes.measureColors['PV'],
        borderColor: colorPalettes.measureColors['PV'],
      })
      datasets.push({
        label: this.$t('measureWALLS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.walls.costs,
        backgroundColor: colorPalettes.measureColors['WALLS'],
        borderColor: colorPalettes.measureColors['WALLS'],
      })
      datasets.push({
        label: this.$t('measureWINDOWS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.windows.costs,
        backgroundColor: colorPalettes.measureColors['WINDOWS'],
        borderColor: colorPalettes.measureColors['WINDOWS'],
      })
      datasets.push({
        label: this.$t('measureROOF'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.roof.costs,
        backgroundColor: colorPalettes.measureColors['ROOF'],
        borderColor: colorPalettes.measureColors['ROOF'],
      })
      datasets.push({
        label: this.$t('measureBASEMENT'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.basement.costs,
        backgroundColor: colorPalettes.measureColors['BASEMENT'],
        borderColor: colorPalettes.measureColors['BASEMENT'],
      })
      return {
        labels: this.data.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.data = await compassApi.call(
        `/projections/${this.portfolio.id}/costs/by_type/${this.scenario.id}?query=${query}`
      )
    },
  },
}
</script>

<style>
.cost-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

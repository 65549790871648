<i18n>
{
  "de": {
    "pageTitle": "Absenkpfad",
    "section": {
      "s1e": "Scope 1 Emissionen",
      "s2e": "Scope 2 Emissionen",
      "s3e": "Scope 3 Emissionen",
      "s12e": "Scope 1-2 Emissionen",
      "s123e": "Scope 1-3 Emissionen",
      "pe": "Primärenergie",
      "penr": "Primärenergie (nicht erneuerbar)",
      "per": "Primärenergie (erneuerbar)",
      "ee": "Endenergie",
      "efficiencyClasses": "Effizienzklassen",
      "heat": "Wärme",
      "electricity": "Elektrizität",
      "costs": "Investitionskosten"
    }
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="report-page">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
      <ScenarioPicker v-model="selectedScenario" :portfolio="portfolio" />

      <PortfolioSearch :portfolio="portfolio" class="portfolio-search" />
      <TagFilters :portfolio="portfolio" :tag-groups="portfolioBuildingTags" class="tag-filters" />

      <Tabs v-if="selectedScenario" group="report-tabs" class="report-tabs">
        <template v-if="portfolio.enabled_features.KPI">
          <Tab
            v-for="indicator in defaultSustainabilityIndicatorIdentifiers"
            :key="indicator"
            :title="getIndicatorName(indicator)"
          >
            <KpiSection
              :kpi-type="indicator"
              :portfolio="portfolio"
              :scenario="selectedScenario"
              :report-chart-data="reportChartData"
            />
          </Tab>
        </template>
        <Tab
          v-if="portfolio.enabled_features.KPI"
          key="section.efficiencyClasses"
          :title="$t('section.efficiencyClasses')"
        >
          <EfficiencyClassesSection :portfolio="portfolio" :scenario="selectedScenario" />
        </Tab>
        <Tab key="section.costs" :title="$t('section.costs')">
          <CostsSection :portfolio="portfolio" :scenario="selectedScenario" :report-chart-data="reportChartData" />
        </Tab>
        <Tab v-if="portfolio.enabled_features.KPI" key="section.heat" :title="$t('section.heat')">
          <HeatSection :portfolio="portfolio" :scenario="selectedScenario" />
        </Tab>
        <Tab key="section.electricity" :title="$t('section.electricity')">
          <ElectricitySection
            :portfolio="portfolio"
            :scenario="selectedScenario"
            :report-chart-data="reportChartData"
            :report-loaded="reportChartDataLoaded"
          />
        </Tab>
      </Tabs>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioBuildingTagsMixin from '@/pages/vuex-mixins/PortfolioBuildingTagsMixin.vue'
import ReportChartDataMixin from '@/pages/vuex-mixins/ReportChartDataMixin.vue'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import PortfolioSearch from '@/components/portfolio/PortfolioSearch.vue'
import TagFilters from '@/components/portfolio/filters/TagFilters.vue'
import KpiSection from '@/components/report/sections/KpiSection.vue'
import EfficiencyClassesSection from '@/components/report/sections/EfficiencyClassesSection.vue'
import HeatSection from '@/components/report/sections/HeatSection.vue'
import ElectricitySection from '@/components/report/sections/ElectricitySection.vue'
import CostsSection from '@/components/report/sections/CostsSection.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'report',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingTagsMixin, // Provides: portfolioBuildingTags, portfolioBuildingTagsLoading, portfolioBuildingTagsLoaded
    ReportChartDataMixin, // Provides reportChartData, reportChartDataLoading, reportChartDataLoaded
  ],

  components: {
    MainLayout,
    Tabs,
    Tab,
    ScenarioPicker,
    KpiSection,
    EfficiencyClassesSection,
    HeatSection,
    ElectricitySection,
    CostsSection,
    PortfolioSearch,
    TagFilters,
    PageTitle,
  },

  data() {
    return {
      selectedScenario: null,
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
      getCurrentPortfolioId: 'ui/getCurrentPortfolioId',
    }),
  },

  updated() {
    if (String(this.portfolio_id) !== String(this.getCurrentPortfolioId())) {
      this.resetQuery({ portfolioId: this.portfolio_id })
      this.setCurrentPortfolioId(this.portfolio_id)
    }
  },

  methods: {
    ...mapActions({
      resetQuery: 'query/reset',
      setCurrentPortfolioId: 'ui/setCurrentPortfolioId',
    }),

    //
    getIndicatorName(identifier) {
      const result = this.__getEnhancedSustainabilityIndicator(identifier)
      if (!result) {
        return this.$t(`section.${identifier}`)
      }
      return result.name
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.report-tabs > .tabs > .tabs-title {
  font-weight: 700;
}

.report-page .scenario-picker {
  margin-bottom: var(--spacing-l);
}
</style>

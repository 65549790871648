<i18n>
{
  "de": {
    "targetPath": "Zielpfad",
    "allBuildings": "Gesamtportfolio ({n} Liegenschaften)",
    "selectedBuildings": "Auswahl ({n} Liegenschaften)",
    "crremApartments": "Zielpfad CRREM 2023 (Mehrfamilienhaus)",
    "crremOffices": "Zielpfad CRREM 2023 (Büro)",
    "bafu": "Zielpfad BAFU PACTA 2022",
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "rh": "Raumwärmebedarf (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (kg CO₂e/m²)",
      "s123e": "Scope 1-3 Emissionen (kg CO₂e/m²)",
      "per": "Primärenergie (erneuerbar) (kWh/m²)"
    },
    "units": {
      "ee": "kWh/m²",
      "rh": "kWh/m²",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    }
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="kpi-compare-selection-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByScenario.faulty" :portfolio="portfolio" />
      <ExportableLineChart :chart-data="chartData" :options="options" />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import ExportableLineChart from '@/components/shared/charts/ExportableLineChart.vue'

import compassApi from '@/services/compass-api.js'
import { mapGetters } from 'vuex'

import crremApartmentsStaticData from '@/data/CRREM_2023_Apartments_StaticData.json'
import crremOfficesStaticData from '@/data/CRREM_2023_Offices_StaticData.json'
import bafuStaticData from '@/data/BAFU_PACTA_2022_StaticData.json'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    ExportableLineChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectionsByScenario: null,
      projectionsByScenarioAll: null,
      roundPrecision: 1,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    targets() {
      const targets = this.portfolio.targets
      if (!targets || (this.kpiType !== 's12e' && this.kpiType !== 'pe')) {
        return []
      }
      return targets.map((x) => {
        if (this.kpiType === 's12e') {
          return {
            year: x.year,
            value: x.co2total,
          }
        } else if (this.kpiType === 'pe') {
          return {
            year: x.year,
            value: x.pe_total,
          }
        }
      })
    },

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.specificUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    crremApartmentsData() {
      return this.projectionsByScenarioAll.years.map((year) => crremApartmentsStaticData[year])
    },

    crremOfficesData() {
      return this.projectionsByScenarioAll.years.map((year) => crremOfficesStaticData[year])
    },

    bafuData() {
      return this.projectionsByScenarioAll.years.map((year) => bafuStaticData[year])
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) =>
                this.getTooltipLabelWithReductionString(item, {
                  precision: this.roundPrecision,
                  unit: this.specificUnit,
                }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
            reverse: true,
          },
        },
      }
    },

    chartData() {
      let datasets = []

      // Add CRREM reduction paths for apartments and offices in Scope 1-3 emissions
      if (this.kpiType === 's123e') {
        datasets.push({
          label: this.$t('crremApartments'),
          borderColor: '#888888',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [5, 5],
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.getStaticData(crremApartmentsStaticData),
        })
        datasets.push({
          label: this.$t('crremOffices'),
          borderColor: '#bbbbbb',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [8, 8],
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.getStaticData(crremOfficesStaticData),
        })
      }

      // Add BAFU reduction path for Scope 1 emissions
      if (this.kpiType === 's1e') {
        datasets.push({
          label: this.$t('bafu'),
          borderColor: '#888888',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [5, 5],
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.getStaticData(bafuStaticData),
        })
      }

      // Add user reduction path targets if available
      if (this.targets.length > 0) {
        datasets.push({
          label: this.$t('targetPath'),
          borderColor: 'rgba(0,0,0,0.1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.projectionsByScenarioAll.years
            .map((year) => this.targets.find((target) => target['year'] === year))
            .map((x) => (x === undefined ? null : x['value'])),
        })
      }

      // Add all buildings in portfolio (if there is a filter applied)
      if (this.projectionsByScenario.nbuildings !== this.projectionsByScenarioAll.nbuildings) {
        datasets.push({
          label: this.$t('allBuildings', { n: this.projectionsByScenarioAll.nbuildings }),
          borderColor: 'rgba(50,0,200,0.3)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.projectionsByScenarioAll.projections[this.scenario.id].value.map(
            (v, i) => v / this.projectionsByScenarioAll.projections[this.scenario.id].area[i]
          ),
        })
      }

      //
      datasets.unshift({
        label: this.$t(
          this.projectionsByScenario.nbuildings !== this.projectionsByScenarioAll.nbuildings
            ? 'selectedBuildings'
            : 'allBuildings',
          { n: this.projectionsByScenario.nbuildings }
        ),
        borderColor: 'rgba(50,0,200,0.9)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 4,
        pointRadius: 2,
        borderWidth: 4,
        fill: false,
        lineTension: 0,
        spanGaps: true,
        pointHitRadius: 20,
        data: this.projectionsByScenario.projections[this.scenario.id].value.map(
          (v, i) => v / this.projectionsByScenario.projections[this.scenario.id].area[i]
        ),
      })

      return {
        labels: this.projectionsByScenarioAll.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.projectionsByScenario = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${query}`
      )
      // Projections for selection and all buildings
      const queryAll = JSON.stringify({})
      this.projectionsByScenarioAll = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${queryAll}`
      )
    },

    getStaticData(source) {
      return this.projectionsByScenarioAll.years.map((year) => source[year])
    },
  },
}
</script>

<style>
.kpi-compare-selection-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

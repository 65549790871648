<i18n>
{
  "de": {
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (kg CO₂e/m²)",
      "s123e": "Scope 1-3 Emissionen (kg CO₂e/m²)",
      "per": "Primärenergie (erneuerbar) (kWh/m²)",
      "el_demand": "Strombedarf (kWh/m²)"
    },
    "units": {
      "ee": "kWh/m²",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²",
      "el_demand": "kWh/m²"
    },
    "modelLabel": "Modell"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="kpi-by-usage-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByUsage.faulty" :portfolio="portfolio" />
      <ExportableBarChart :chart-data="chartData" :options="options" />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import FaultsContainer from '@/components/report/charts/FaultsContainer'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    ExportableBarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectionsByUsage: null,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.specificUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  title: this.$t('modelLabel'),
                  precision: 1,
                  unit: this.specificUnit,
                }),
              label: (item) => this.getTooltipLabelWithPercentage(item, { unit: this.specificUnit }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
            labels: {
              padding: 15,
            },
          },
        },
      }
    },

    chartData() {
      const sortOrder = [
        //
        'GENERAL',
        'TENANTS',
        'ROOM_HEAT',
        'HOT_WATER',
      ]
      let datasets = []
      for (const usage in this.projectionsByUsage.projections) {
        datasets.push({
          usageCode: usage,
          label: this.$t(`_energyUsages.${usage}`),
          backgroundColor: colorPalettes.usageColors[usage],
          data: this.projectionsByUsage.projections[usage].value.map(
            (v, i) => v / this.projectionsByUsage.projections[usage].area[i]
          ),
          stack: 'Calculated',
        })
      }
      datasets.sort((a, b) => sortOrder.indexOf(a.usageCode) - sortOrder.indexOf(b.usageCode))
      return {
        labels: this.projectionsByUsage.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.projectionsByUsage = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/by_usage/${this.scenario.id}?query=${query}`
      )
    },
  },
}
</script>

<style>
.kpi-by-usage-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

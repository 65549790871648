<i18n>
{
  "de": {
    "selectScenario": "Szenario wählen:"
  }
}
</i18n>

<template>
  <div class="c-scenario-picker">
    <span class="select-scenario">{{ $t('selectScenario') }}</span>

    <Menu left :buttonText="model.description">
      <template #icon>
        <ScenarioIcon :color="model.color" />
      </template>
      <template #options>
        <MenuItem
          v-for="scenario in portfolio.scenarios"
          :id="`scenario-${scenario.id}`"
          :key="scenario.id"
          :label="scenario.description"
          :type="'label'"
          :value="scenario"
          @click="selectScenario"
        >
          <template #icon>
            <ScenarioIcon :color="scenario.color" />
          </template>
        </MenuItem>
      </template>
    </Menu>
  </div>
</template>

<script>
import ScenarioIcon from '@/components/shared/ScenarioIcon.vue'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuItem,
    ScenarioIcon,
  },

  props: {
    portfolio: {
      type: Object,
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  watch: {
    value() {
      this.model = this.value
    },
  },

  created() {
    if (this.value) {
      this.model = this.value
    } else {
      this.model = this.portfolio.scenarios[0]
      this.$emit('input', this.model)
    }
  },

  methods: {
    selectScenario(scenario) {
      this.model = scenario
      this.$emit('input', this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-scenario-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-l);

  & .select-scenario {
    margin-right: var(--spacing-s);
  }
}
</style>

<i18n>
{
  "de": {
    "xLabel": "Liegenschaft (sortiert nach Rentabilität der PV-Anlage)",
    "yLabel": "Deckung Jahresbedarf Gesamtportfolio (%)",
    "productionTooltip": "Ertrag Photovoltaik",
    "yieldRatioTooltip": "Rendite",
    "default": "Ertrag",
    "descriptionLabel": "Liegenschaft",
    "reductionLabel": "Ertrag in MWh",
    "rest": "Rest"
  }
}
</i18n>

<template>
  <div class="c-pv-pareto-chart">
    <ExportableBarChart v-if="buildings" :chart-data="chartData" :options="options" />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin, // provides onChartClick
  ],

  components: {
    ExportableBarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
    scenario: {
      type: Object,
    },
    sortby: {
      type: String,
      default: 'pv_yield_rate',
    },
  },

  computed: {
    buildings() {
      let buildings = [
        ...this.reportChartData.filter(
          (b) =>
            b.scenarios_data !== null &&
            (!this.scenario || b.scenarios_data.find((m) => m.scenario_id === this.scenario.id)?.pv_yield_rate !== null)
        ),
      ]
      if (this.sortby === 'pv_yield_rate') {
        buildings.sort((a, b) => this.getVal(b).pv_yield_rate - this.getVal(a).pv_yield_rate)
      }
      return buildings
    },

    totalDemand() {
      return this.buildings.reduce((pv, cv) => pv + cv.el_demand, 0)
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onChartClick,
        scales: {
          xAxis: {
            stacked: true,
            title: {
              display: true,
              text: this.$t('xLabel'),
            },
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            displayColors: false,
            callbacks: {
              title: this.tooltipTitle,
              beforeBody: this.tooltipBeforeBody,
              label: this.tooltipLabel,
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      const datasets = []
      const series = []
      const descriptions = []
      const production = []
      const labels = []
      let rest = []
      // eslint-disable-next-line no-unused-vars
      let last
      let restValue = 0

      let cumulativeProduction = 0
      this.buildings.map((building, index) => {
        // https://stackoverflow.com/questions/38703114/chart-js-plugin-for-making-waterfall-charts
        const vStart = cumulativeProduction
        const value = this.getVal(building)?.el_production
        cumulativeProduction += value
        // Handle the first 20 buildings, and then add a "rest" building
        if (index < 20) {
          last = (cumulativeProduction / this.totalDemand) * 100
          descriptions.push(building.description)
          series.push([(vStart / this.totalDemand) * 100, last])
          production.push(value)
          labels.push(
            building.description.length < 30 ? building.description : building.description.substring(0, 30) + '...'
          )
        } else {
          restValue += value
          rest = [last, (cumulativeProduction / this.totalDemand) * 100]
        }
      })
      // Add the "rest" building, if there are more than 20 buildings
      if (this.buildings.length > 20) {
        descriptions.push(this.$t('rest'))
        series.push(rest)
        production.push(restValue)
        labels.push(this.$t('rest'))
      }

      datasets.push({
        label: this.$t('default'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: series,
        backgroundColor: this.buildings.map(
          (b) => this.portfolio.scenarios.find((s) => s.id === this.getVal(b)?.scenario_id)?.color
        ),
        borderColor: colorPalettes.measureColors['ENVELOPE'],
      })
      datasets.push({
        label: this.$t('descriptionLabel'),
        hidden: true,
        data: descriptions,
      })
      datasets.push({
        label: this.$t('reductionLabel'),
        hidden: true,
        data: production,
      })

      return {
        labels: labels,
        datasets: datasets,
      }
    },
  },

  methods: {
    getVal(building) {
      if (this.scenario !== undefined) {
        return building.scenarios_data.find((m) => m.scenario_id === this.scenario.id)
      } else {
        if (this.sortby === 'pv_yield_rate') {
          let sorted = [...building.scenarios_data]
          sorted.sort((a, b) => b.pv_yield_rate - a.pv_yield_rate)
          return sorted[0]
        }
      }
    },

    tooltipTitle(items) {
      return this.chartData.labels[items[0].dataIndex]
    },

    tooltipBeforeBody(items) {
      const value = this.chartData.datasets[2].data[items[0].dataIndex]
      return `${this.$t('productionTooltip')}: ${this.formatNumber(value / 1000, 0)} MWh`
    },

    tooltipLabel(item) {
      const [start, end] = this.chartData.datasets[0].data[item.dataIndex]
      const value = end - start
      return `${this.$t('yieldRatioTooltip')}: ${value ? `${this.formatNumber(value, 1)} %` : '-'}`
    },

    // Override the mixin method
    onChartClick(event, items) {
      if (items.length !== 0) {
        if (items[0].index < 20) {
          this.$emit('click', this.buildings[items[0].index])
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pv-pareto-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>

<i18n>
{
  "de": {
    "yLabelDemand": "Strombedarf (kWh/m²)",
    "yLabelProduction": "Stromproduktion (kWh/m²)",
    "yLabelAbsoluteProduction": "Stromproduktion (MWh)",
    "ownUseLabel": "Eigenverbrauch",
    "gridLabel": "Stromnetz",
    "feedinLabel": "Netzrückspeisung",
    "total": "Total"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="own-use-chart">
    <template #default>
      <FaultsContainer :faults="data.faulty" :portfolio="portfolio" />
      <ExportableBarChart :chart-data="chartData" :options="options" />
    </template>
  </AsyncContentContainer>
</template>

<script>
import _ from 'lodash'

import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    ExportableBarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
    demandPerspective: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      data: null,
      roundPrecision: 1,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.$t(
                this.demandPerspective
                  ? 'yLabelDemand'
                  : this.absolute
                  ? 'yLabelAbsoluteProduction'
                  : 'yLabelProduction'
              ),
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              beforeBody: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  title: this.$t('total'),
                  unit: this.absolute ? 'MWh' : 'kWh/m2',
                }),
              label: (item) => this.getTooltipLabelWithPercentage(item, { unit: this.absolute ? 'MWh' : 'kWh/m2' }),
            },
          },
          legend: {
            display: !_.isEmpty(this.data.projections),
            position: 'bottom',
            align: 'start',
            reverse: true,
            labels: {
              padding: 15,
            },
          },
        },
      }
    },

    chartData() {
      let datasets = []
      if (this.data.projections.ELECTRICITY_OWNUSE !== undefined) {
        datasets.push({
          label: this.$t('ownUseLabel'),
          backgroundColor: colorPalettes.carrierColors['ELECTRICITY_OWNUSE'],
          data: this.data.projections.ELECTRICITY_OWNUSE.value.map((v, i) =>
            this.absolute ? v / 1000 : v / this.data.projections.ELECTRICITY_OWNUSE.area[i]
          ),
          stack: 'Calculated',
        })
      }
      if (this.demandPerspective) {
        if (this.data.projections.ELECTRICITY_GRID !== undefined) {
          datasets.push({
            label: this.$t('gridLabel'),
            backgroundColor: colorPalettes.carrierColors['ELECTRICITY_GRID'],
            data: this.data.projections.ELECTRICITY_GRID.value.map((v, i) =>
              this.absolute ? v / 1000 : v / this.data.projections.ELECTRICITY_GRID.area[i]
            ),
            stack: 'Calculated',
          })
        }
      } else {
        if (this.data.projections.ELECTRICITY_FEEDIN !== undefined) {
          datasets.push({
            label: this.$t('feedinLabel'),
            backgroundColor: colorPalettes.carrierColors['ELECTRICITY_FEEDIN'],
            data: this.data.projections.ELECTRICITY_FEEDIN.value.map((v, i) =>
              this.absolute ? v / 1000 : v / this.data.projections.ELECTRICITY_FEEDIN.area[i]
            ),
            stack: 'Calculated',
          })
        } else {
          datasets.push({})
        }
      }
      return {
        labels: this.data.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      if (this.demandPerspective) {
        this.data = await compassApi.call(
          `/projections/${this.portfolio.id}/el_demand/by_carrier/${this.scenario.id}?query=${query}`
        )
      } else {
        this.data = await compassApi.call(
          `/projections/${this.portfolio.id}/el_production/by_carrier/${this.scenario.id}?query=${query}`
        )
      }
    },
  },
}
</script>

<style>
.own-use-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
